<template>
	<v-layout row>
		<LabelledCounter
			v-for="status in statuses"
			:key="status.id"
			:count="getCounter(status.id)"
			:label="$t(status.name)"
			:color="status.color"
			:loading="loading > 0"
			:clickable="true"
			:minimized="minimized"
			@click="setStatusFilter(status)"
		/>
	</v-layout>
</template>

<script>
import { mapState } from 'vuex'
import TasksManagerModuleGuard from '@/mixins/ModulesGuards/TasksManager/TasksManagerModuleGuard'

export default {
	name: 'TasksManagerToolbarCounters',
	components: {
		LabelledCounter: () => ({
			component: import('@/components/Commons/LabelledCounter')
		})
	},
	mixins: [TasksManagerModuleGuard],
	data: function () {
		return {
			loading: 0,
			statusCounters: []
		}
	},
	computed: {
		...mapState({
			isAccountant: state => state.auth.isAccountant,
			accountingFirm: state => state.accountingFirm.selected,
			company: state => state.company.selected,
			filters: state => state.tasks.filters,
			statuses: state => state.tasks.statuses
		}),
		minimized: function () {
			return this.$vuetify.breakpoint.smAndDown
		}
	},
	watch: {
		accountingFirm: {
			handler: function () {
				this.loadCounters()
			},
			deep: true
		},
		company: {
			handler: function () {
				if (!this.isAccountant) {
					this.loadCounters()
				}
			},
			deep: true
		},
		filters: {
			deep: true,
			immediate: false,
			handler: function () {
				this.loadCounters()
			}
		}
	},
	created: function () {
		if (this.loading === 0) {
			this.loadCounters()
		}
	},
	methods: {
		getCounter: function (statusId) {
			let result = 0
			if (this.statusCounters.some(counter => counter.task_status_id === statusId)) {
				result = this.statusCounters.find(counter => counter.task_status_id === statusId).counter
			}
			return result
		},
		loadCounters: function () {
			this.loading++
			this.service
				.getTasksCounters()
				.then(res => {
					if (res) {
						this.statusCounters = [...res]
					}
				})
				.finally(this.loading--)
		},
		setStatusFilter: function (status) {
			this.filters.statuses.clear()
			this.filters.statuses.push(status.id)
		},
		getModuleEventsActionsMapping: function () {
			return [
				{ event: this.events.TASK_DELETED, action: this.loadCounters }
			]
		},
	}
}
</script>
